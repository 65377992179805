$card-cap-bg: #f8f9fc;

$blue: #4e63df !default;

$dropdown-link-active-bg: #515151 !default;

$font-family-sans-serif: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$font-weight-light: 300 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken(#e9ecef, 2%) !default;

// Dropdowns
$dropdown-font-size: 0.85rem !default;
$dropdown-border-color: $border-color !default;

$list-group-bg: #f8f9fa !default;

$list-group-hover-bg: #ffffff !default;

$list-group-active-bg: #646464 !default;

$pagination-transition: none;

$zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
);
