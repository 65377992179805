.edit-content {
    position: absolute;
    top: 10px;
    right: 10px;
}

td:hover div.env-cell-empty {
    opacity: 1;
}

.text-promote-from {
    color: $primary !important;
}
.text-promote-to {
    color: $warning !important;
}

tr.promote-from {
    background-color: rgba($color: $primary, $alpha: 0.2) !important;
}
tr.promote-to {
    background-color: rgba($color: $warning, $alpha: 0.2) !important;
}

th.promote-from {
    background-color: rgba($color: $primary, $alpha: 0.2) !important;
}

td.promote-from {
    background-color: rgba($color: $primary, $alpha: 0.2) !important;
}

td.promote-to {
    background-color: rgba($color: $warning, $alpha: 0.2) !important;
}
th.promote-to {
    background-color: rgba($color: $warning, $alpha: 0.2) !important;
}

.custom-btn {
    background-color: #1899d6;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 7px 10px;
    transform: translateZ(0);
    // white-space: nowrap;
}

.custom-btn:after {
    background-clip: padding-box;
    background-color: #1cb0f6;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

table.table-overview {
    height: 1px;
    tr {
        height: 100%;
    }
    td {
        height: 100%;
    }
}
