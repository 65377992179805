.variants {
    .variant {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.7);
        transition: 0.4s;
    }

    .variant-color-1 {
        background-color: #e8f0fe !important;
        color: #1a73e8 !important;
        transition: 0.4s;
    }

    .variant-color-2 {
        background-color: #ede7f6 !important;
        color: #5e35b1 !important;
        transition: 0.4s;
    }

    .variant-color-3 {
        background-color: #fff3e0 !important;
        color: #fb8c00 !important;
        transition: 0.4s;
    }

    .variant-color-4 {
        background-color: #fce4ec !important;
        color: #d81b60 !important;
        transition: 0.4s;
    }

    .variant-color-5 {
        background-color: #f1f8e9 !important;
        color: #7cb342 !important;
        transition: 0.4s;
    }

    .variant-color-6 {
        background-color: #fbe9e7 !important;
        color: #f4511e !important;
        transition: 0.4s;
    }

    .variant-color-7 {
        background-color: #e0f2f1 !important;
        color: #00897b !important;
        transition: 0.4s;
    }
}

.experiment-preview {
    --pill-bg: #e4f1fc;
}
