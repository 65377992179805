.rotate-15 {
    transform: rotate(15deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-n-15 {
    transform: rotate(-15deg);
}
