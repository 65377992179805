.react-select-sm {
    font-size: 13px;

    .select__control {
        min-height: 31px;

        .select__indicator-separator {
            margin-bottom: 4px;
            margin-top: 4px;
        }

        .select__value-container {
            padding: 0px 6px;

            .select__multi-value__label {
                padding: 1px 4px;
            }
        }
        .select__indicator {
            padding: 4px;
        }
    }
}

.react-select {
    .select__menu {
        z-index: 100;
    }
}

.react-select-no-border {
    border-radius: 0;

    .select__control {
        border-radius: 0;
        border-width: 0;
    }
    .select__indicator-separator {
        display: none;
    }
}
.react-select-rounded-sm {
    .select__control {
        border-top-left-radius: var(--bs-border-radius-sm);
        border-bottom-left-radius: var(--bs-border-radius-sm);
    }
}
