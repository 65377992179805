.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
}

.backdrop-top {
    z-index: 1051;
}
