.hints-container {
    position: fixed;
    z-index: 1515;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 34%;
}

.hint {
    opacity: 0;
    transform: translate(-150px, 0);
    border-radius: 2.5rem 2.5rem 2.5rem 0px;
    min-width: 250px;
    transform-origin: center;
    display: flex;
    align-items: center;

    transition: opacity 0.3s ease-out, transform 0.2s ease-in-out;
    -webkit-transition: opacity, 0.3s ease-out, transform 0.2s ease-in-out;
    -moz-transition: opacity 0.3s ease-out, transform 0.2s ease-in-out;
    -ms-transition: opacity 0.3s ease-out, transform 0.2s ease-in-out;
    -o-transition: opacity 0.3s ease-out, transform 0.2s ease-in-out;
}

.hint-appearing {
    opacity: 1;
    transform: translate(0, 0);
}

.hint-hiding {
    opacity: 0 !important;
}
