[data-bs-theme='dark'] {
    --#{$prefix}gray-900: #f8f9fa;
    --#{$prefix}gray-800: #e9ecef;
    --#{$prefix}gray-700: #dee2e6;
    --#{$prefix}gray-600: #ced4da;
    --#{$prefix}gray-500: #adb5bd;
    --#{$prefix}gray-400: #6c757d;
    --#{$prefix}gray-300: #495057;
    --#{$prefix}gray-200: #343a40;
    --#{$prefix}gray-100: #2c2e2f;

    --#{$prefix}body-bg: #202224;

    .topbar {
        background-color: var(--lf-project-color-4, rgba(46,31,45, 1)) !important;
    }

    .sidebar {
        background-image: linear-gradient(180deg, var(--lf-project-color-4, #000000) 10%, var(--lf-project-color-4, #000000));
    }

    .react-loading-skeleton {
        --base-color: var(--#{$prefix}body-bg);
        --highlight-color: var(--#{$prefix}gray-200);
    }

    .dropdown-menu {
        background-color: var(--#{$prefix}dropdown-bg) !important;
        --#{$prefix}dropdown-divider-bg: var(--#{$prefix}border-color);
    }

    .dropdown-menu > div {
        border-color: var(--#{$prefix}border-color);
        background-color: inherit;
    }

    .card {
        --#{$prefix}card-cap-bg: #2c2e2f;
        --#{$prefix}card-border-color: var(--#{$prefix}border-color);
    }

    .list-group {
        --#{$prefix}list-group-color: var(--#{$prefix}body-color);
        --#{$prefix}list-group-bg: #2c2e2f;
        --#{$prefix}list-group-disabled-bg: var(--#{$prefix}gray-200);
        --#{$prefix}list-group-action-hover-bg: var(--#{$prefix}body-bg);
        --#{$prefix}list-group-disabled-color: var(--#{$prefix}white);

        // .list-group-item:hover {
        //     background-color: var(--#{$prefix}list-group-action-hover-bg) !important;
        // }
        // .list-group-item.disabled:hover {
        //     background-color: var(--#{$prefix}list-group-disabled-bg) !important;
        // }
    }

    .nav-tabs {
        .nav-link {
            color: var(--#{$prefix}nav-link-color);
        }
        .nav-link.active {
            color: var(--#{$prefix}nav-link-active-color);
        }
        .nav-link.disabled {
            color: var(--#{$prefix}nav-link-disabled-color);
        }
    }

    .btn {
        --#{$prefix}btn-color: var(--#{$prefix}gray-600);
    }

    .shadow-sm {
        box-shadow: 0 0.125rem 0.25rem 0 rgba(201, 202, 204, 0.178) !important;
    }
    .dropdown-menu {
        --#{$prefix}dropdown-border-color: #495057 !important;
    }

    .bg-white {
        background-color: var(--#{$prefix}gray-100) !important;
    }

    .border-white {
        border-color: var(--#{$prefix}gray-100) !important;
    }

    .collapse-item {
        color: var(--#{$prefix}body-color) !important;
    }
    .collapse-item:hover {
        color: var(--#{$prefix}white) !important;
        background-color: var(--#{$prefix}tertiary-bg) !important;
    }

    @each $level, $value in $grays {
        .bg-gray-#{$level} {
            background-color: var(--#{$prefix}gray-#{$level}) !important;
        }
        .text-gray-#{$level} {
            color: var(--#{$prefix}gray-#{$level}) !important;
        }
    }

    @each $color, $value in $theme-colors {
        .bg-gradient-#{$color} {
            background-color: $value;
            background-image: linear-gradient(180deg, darken($value, 25%) 10%, darken($value, 30%) 100%);

            background-size: cover;
        }
    }

    @import 'dark/react-select';
    @import 'dark/scrollbar';

    .badge.text-black {
        color: var(--#{$prefix}white) !important;
    }

    .bg-deep_orange {
        background: rgba(244, 81, 30, 0.5);
    }

    .bg-orange {
        background: rgba(251, 140, 0, 0.5);
    }

    .bg-pink {
        background: rgba(216, 27, 96, 0.5);
    }

    .bg-blue {
        background: rgba(30, 136, 229, 0.5);
    }

    .bg-green {
        background: rgba(67, 160, 71, 0.5);
    }

    .bg-lime {
        background: rgba(124, 179, 66, 0.5);
    }

    .bg-purple {
        background: rgba(142, 36, 170, 0.5);
    }

    .bg-brown {
        background: rgba(109, 76, 65, 0.5);
    }

    .bg-indigo {
        background: rgba(57, 73, 171, 0.5);
    }

    .bg-cyan {
        background: rgba(0, 172, 193, 0.5);
    }

    .bg-teal {
        background: rgba(0, 137, 123, 0.5);
    }
    .border-orange {
        border-color: rgba(251, 140, 0, 0.12);
    }

    .border-pink {
        border-color: rgba(216, 27, 96, 0.12);
    }

    .border-blue {
        border-color: rgba(30, 136, 229, 0.12);
    }

    .border-green {
        border-color: rgba(67, 160, 71, 0.12);
    }

    .border-lime {
        border-color: rgba(124, 179, 66, 0.12);
    }

    .border-purple {
        border-color: rgba(142, 36, 170, 0.12);
    }

    .border-brown {
        border-color: rgba(109, 76, 65, 0.12);
    }

    .border-indigo {
        border-color: rgba(57, 73, 171, 0.12);
    }

    .border-cyan {
        border-color: rgba(0, 172, 193, 0.12);
    }

    .border-teal {
        border-color: rgba(0, 137, 123, 0.12);
    }

    .json-key {
        color: rgb(251, 44, 44);
    }

    .json-value {
        color: rgb(39, 244, 32);
    }

    .json-string {
        color: rgb(255, 255, 14);
    }

    .diff-removed {
        background-color: #ffebe9;
    }

    .diff-added {
        background-color: #e6ffec;
    }

    .diff-removed-char {
        background-color: #ffbdbe;
    }

    .diff-added-char {
        background-color: #abf2bc;
    }

    .btn-light {
        --#{$prefix}btn-color: #ffffff;
        --#{$prefix}btn-hover-color: var(--#{$prefix}gray-700);
        --#{$prefix}btn-bg: var(--#{$prefix}gray-100);
        --#{$prefix}btn-border-color: var(--#{$prefix}gray-100);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-100);
        --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-200);
        --#{$prefix}btn-disabled-color: var(--#{$prefix}gray-400);
        --#{$prefix}btn-disabled-bg: var(--#{$prefix}tertiary-bg);
        --#{$prefix}btn-disabled-border-color: var(--#{$prefix}tertiary-bg);
    }

    .highlight-within-textarea {
        color: var(--#{$prefix}white);
        background-color: var(--#{$prefix}tertiary-bg);
        border-color: var(--#{$prefix}tertiary-bg);

        .red-bg {
            background-color: #9a0303;
        }
    }

    .variants {
        .variant {
            background-color: rgba(0, 0, 0, 0.04);
            color: rgba(255, 255, 255, 0.689);
            transition: 0.4s;
        }

        .variant-color-1 {
            background-color: darken(#e8f0fe, 50%) !important;
            color: lighten(#1a73e8, 50%) !important;
        }

        .variant-color-2 {
            background-color: darken(#ede7f6, 50%) !important;
            color: lighten(#5e35b1, 50%) !important;
        }
        .variant-color-3 {
            background-color: darken(#fff3e0, 50%) !important;
            color: lighten(#fb8c00, 50%) !important;
        }
        .variant-color-4 {
            background-color: darken(#fce4ec, 50%) !important;
            color: lighten(#d81b60, 50%) !important;
        }
        .variant-color-5 {
            background-color: darken(#f1f8e9, 50%) !important;
            color: lighten(#7cb342, 50%) !important;
        }
        .variant-color-6 {
            background-color: darken(#fbe9e7, 50%) !important;
            color: lighten(#f4511e, 50%) !important;
        }
        .variant-color-7 {
            background-color: darken(#e0f2f1, 50%) !important;
            color: lighten(#00897b, 50%) !important;
        }
    }

    .text-dark {
        color: var(--#{$prefix}white) !important;
    }

    .experiment-preview {
        --pill-bg: #104b6a;
    }
}
