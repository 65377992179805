.audience-editing {
    overflow: auto;

    .expressions .conditions {
        min-width: 760px;
    }

    .basic-multi-select {
        font-size: 14px;
    }

    .condition__control {
        min-height: 20px;
    }

    .condition__indicator {
        padding: 0px 4px;
    }
    .condition__indicator-separator {
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .condition__value-container {
        padding: 0px 4px;
    }

    .condition__multi-value__label {
        padding: 0px 3px;
    }
}

@include media-breakpoint-up(lg) {
    .audience-editing {
        overflow: unset;
    }
}
