.icon-1 {
    height: 13rem;
    width: 13rem;
}

.icon-2 {
    height: 10rem;
    width: 10rem;
}

.icon-3 {
    height: 3.5rem;
    width: 3.5rem;
}

.icon-4 {
    width: 45px;
    height: 45px;
}

.icon-5 {
    height: 1.5rem;
    width: 1.5rem;
}

.icon-6 {
    height: 1rem;
    width: 1rem;
}

.service-icon {
    align-items: center;
    background-color: #4b4b4b;
    border-radius: 4px;
    color: #fefefe;
    display: flex;
    font-size: 17px;
    height: 34px;
    justify-content: center;
    min-width: 34px;
    width: 34px;
}
