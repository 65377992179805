// .custom-control
// {
// 	z-index: auto;
// }

.list-group-item.active {
    z-index: auto;
}

.table-hover tbody tr {
    cursor: pointer;
}

.small {
    font-size: 80%;
    font-weight: 400;
}

.input-group .btn {
    z-index: auto;
}
