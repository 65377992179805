// Animation Utilities

// Grow In Animation
.animation-none {
    -webkit-transition: none;
    transition: none;
}

@keyframes growIn {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.animated--grow-in {
    animation-name: growIn;
    animation-duration: 200ms;
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

// Fade In Animation

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated--fade-in {
    animation-name: fadeIn;
    animation-duration: 200ms;
    animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}


.animated--fade-out {
    animation-name: fadeOut;
    animation-duration: 200ms;
    animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}
