$sidebar-base-width: 233px !default;

.sidebar-parent {
    width: calc(#{$sidebar-base-width} + 17px);
    z-index: 3 !important;
}
.sidebar-tutorial {
    width: 600px;
    z-index: 51 !important;
}
.sidebar-tutorial-overlay {
    z-index: 50 !important;
}

// Sidebar
.sidebar-navigation {
    background-image: linear-gradient(
        180deg,
        var(--lf-project-color-2, #323f4b) 10%,
        var(--lf-project-color-3, #252a2f)
    );
}
.sidebar {
    min-height: 100%;

    .nav-item {
        position: relative;

        &:last-child {
            margin-bottom: 1rem;
        }

        .nav-link {
            display: block;
            width: 100%;
            text-align: left;
            padding: 1rem;
            width: $sidebar-base-width;

            i {
                font-size: 0.85rem;
                margin-right: 0.25rem;
            }

            span {
                font-size: 0.85rem;
                display: inline;
            }

            // Accordion Arrow Icon
            &[data-bs-toggle='collapse'] {
                &::after {
                    width: 1rem;
                    text-align: center;
                    float: right;
                    vertical-align: 0;
                    border: 0;
                    font-weight: 900;
                    content: '\f107';
                    font-family: 'Font Awesome 5 Free';
                }

                &.collapsed::after {
                    content: '\f105';
                }
            }
        }

        &.active {
            .nav-link {
                font-weight: 700;
            }
        }

        // Accordion
        .collapse {
            position: relative;
            left: 0;
            z-index: 1;
            top: 0;
            animation: none;

            .collapse-inner {
                border-radius: 0;
                box-shadow: none;
            }
        }

        .collapsing {
            display: block;
            transition: height 0.15s ease;
        }

        .collapse,
        .collapsing {
            margin: 0 1rem;

            .collapse-inner {
                padding: 0.5rem 0;
                min-width: 10rem;
                font-size: $dropdown-font-size;
                margin: 0 0 1rem 0;

                .collapse-header {
                    margin: 0;
                    white-space: nowrap;
                    padding: 0.5rem 1.5rem;
                    text-transform: uppercase;
                    font-weight: 800;
                    font-size: 0.65rem;
                    color: $gray-500;
                }

                .collapse-item {
                    padding: 0.5rem 1rem;
                    margin: 0 0.5rem;
                    display: block;
                    color: $gray-900;
                    text-decoration: none;
                    border-radius: $border-radius;
                    white-space: nowrap;

                    &:hover {
                        background-color: $gray-200;
                    }

                    &:active {
                        background-color: $gray-300;
                    }

                    &.active {
                        color: $primary;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .sidebar-game-title {
        display: none !important;
    }

    hr.sidebar-divider {
        margin: 0 1rem 1rem;
    }

    .sidebar-heading {
        text-align: left;
        padding: 0 1rem;
        font-weight: 800;
        font-size: 0.65rem;
        @extend .text-uppercase;
    }
}

@include media-breakpoint-up(md) {
    .sidebar {
        .sidebar-game-title {
            font-size: 1rem !important;
            width: 147px;
            float: right;
            display: inline !important;
        }
    }
}

.sidebar-dark {
    hr.sidebar-divider {
        border-top: 1px solid fade-out($white, 0.85);
    }

    .sidebar-heading {
        color: fade-out($white, 0.6);
    }

    .nav-item {
        .nav-link {
            color: fade-out($white, 0.2);

            i {
                color: fade-out($white, 0.7);
            }

            &:active,
            &:focus,
            &:hover {
                color: $white;

                i {
                    color: $white;
                }
            }

            // Accordion
            &[data-bs-toggle='collapse']::after {
                color: fade-out($white, 0.5);
            }
        }

        &.active {
            .nav-link {
                color: $white;

                i {
                    color: $white;
                }
            }
        }
    }
}
