// Background Gradient Utilities

@each $color, $value in $theme-colors {
    .bg-gradient-#{$color} {
        background-color: $value;
        background-image: linear-gradient(180deg, $value 10%, darken($value, 15%) 100%);
        background-size: cover;
    }
}

// Grayscale Background Utilities

@each $level, $value in $grays {
    .bg-gray-#{$level} {
        background-color: $value !important;
    }
}

.bg-deep_orange {
    background: rgba(244, 81, 30, 0.12) !important;
}

.bg-orange {
    background: rgba(251, 140, 0, 0.12) !important;
}

.bg-pink {
    background: rgba(216, 27, 96, 0.12) !important;
}

.bg-blue {
    background: rgba(30, 136, 229, 0.12) !important;
}

.bg-green {
    background: rgba(67, 160, 71, 0.12) !important;
}

.bg-lime {
    background: rgba(124, 179, 66, 0.12) !important;
}

.bg-purple {
    background: rgba(142, 36, 170, 0.12) !important;
}

.bg-brown {
    background: rgba(109, 76, 65, 0.12) !important;
}

.bg-indigo {
    background: rgba(57, 73, 171, 0.12) !important;
}

.bg-cyan {
    background: rgba(0, 172, 193, 0.12) !important;
}

.bg-teal {
    background: rgba(0, 137, 123, 0.12) !important;
}

.border-deep_orange {
    border-color: rgba(244, 81, 30, 0.5) !important;
}

.border-orange {
    border-color: rgba(251, 140, 0, 0.5) !important;
}

.border-pink {
    border-color: rgba(216, 27, 96, 0.5) !important;
}

.border-blue {
    border-color: rgba(30, 136, 229, 0.5) !important;
}

.border-green {
    border-color: rgba(67, 160, 71, 0.5) !important;
}

.border-lime {
    border-color: rgba(124, 179, 66, 0.5) !important;
}

.border-purple {
    border-color: rgba(142, 36, 170, 0.5) !important;
}

.border-brown {
    border-color: rgba(109, 76, 65, 0.5) !important;
}

.border-indigo {
    border-color: rgba(57, 73, 171, 0.5) !important;
}

.border-cyan {
    border-color: rgba(0, 172, 193, 0.5) !important;
}

.border-teal {
    border-color: rgba(0, 137, 123, 0.12) !important;
}
