// Global styles for both custom sidebar and topbar compoments
$topbar-base-height: 3.2rem !default;

.sidebar,
.topbar {
    .nav-item {
        // Customize Dropdown Arrows for Navbar
        &.dropdown {
            .dropdown-toggle {
                &::after {
                    width: 1rem;
                    text-align: center;
                    float: right;
                    vertical-align: 0;
                    border: 0;
                    font-weight: 900;
                    content: '\f105';
                    font-family: 'Font Awesome 5 Free';
                }
            }
            &.show {
                .dropdown-toggle::after {
                    content: '\f107';
                }
            }
        }
        // Counter for nav links and nav link image sizing
        .nav-link {
            position: relative;
            .badge-counter {
                position: absolute;
                transform: scale(0.7);
                transform-origin: top right;
                right: 0.25rem;
                margin-top: -0.25rem;
            }
            .img-profile {
                height: 2rem;
                width: 2rem;
            }
        }
    }
}
