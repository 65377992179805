.json-key {
    color: brown;
}

.json-value {
    color: navy;
}

.json-string {
    color: olive;
}

.diff-line {
    display: block;
    padding-left: 15px;
}

.diff-removed {
    background-color: #ffebe9;
}

.diff-added {
    background-color: #e6ffec;
}

.diff-updated {
    background-color: #fcffe6;
}
.diff-key {
    font-weight: 600;
    padding-left: 0;
    margin-top: 5px;
}

.diff-hidden {
    visibility: hidden;
}
.diff-removed-char {
    background-color: #ffbdbe;
}

.diff-added-char {
    background-color: #abf2bc;
}
