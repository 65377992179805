.highlight-within-textarea {
    .public-DraftStyleDefault-pre {
        overflow: unset;

        pre {
            overflow: unset;
        }
    }

    background-color: #ffffff;
    height: 350px;
    border: 1px solid #ced4da;
    text-align: left;
    overflow: scroll;

    padding: 0.375rem 0.75rem;
    border-radius: 0.35rem;

    .red-bg {
        background-color: #ffa8a8;
    }
}
