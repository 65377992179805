.tooltip-danger {
	&.bs-tooltip-top .tooltip-arrow {
		&::before {
			border-top-color: $danger !important;
		}
	}
	&.bs-tooltip-bottom .tooltip-arrow {
		&::before {
			border-bottom-color: $danger !important;
		}
	}
	&.bs-tooltip-start .tooltip-arrow {
		&::before {
			border-left-color: $danger !important;
		}
	}
	&.bs-tooltip-end .tooltip-arrow {
		&::before {
			border-right-color: $danger !important;
		}
	}

	.tooltip-inner {
		background-color: $danger;
		border-color: $danger;
		color: $light;
	}
}

.tooltip-light {
	&.bs-tooltip-top .tooltip-arrow {
		&::before {
			border-top-color: $white !important;
		}
	}
	&.bs-tooltip-bottom .tooltip-arrow {
		&::before {
			border-bottom-color: $white !important;
		}
	}
	&.bs-tooltip-start .tooltip-arrow {
		&::before {
			border-left-color: $white !important;
		}
	}
	&.bs-tooltip-end .tooltip-arrow {
		&::before {
			border-right-color: $white !important;
		}
	}

	.tooltip-inner {
		box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
		background-color: $white;
		color: $dark;
	}
}

