// Custom Dropdown Styling

.dropdown {
    .dropdown-menu {
        font-size: $dropdown-font-size;
        .dropdown-header {
            @extend .text-uppercase;
            font-weight: 800;
            font-size: 0.65rem;
            color: $gray-500;
        }
    }
}

// Utility class to hide arrow from dropdown

.dropdown.no-arrow {
    .dropdown-toggle::after {
        display: none;
    }
}

.dropdown-item {
    padding: 0.55rem 1rem;
    min-width: 200px;
}

.dropdown-hover:hover > .dropdown-menu {
    display: none;
}
.dropdown-hover:hover > .dropdown-menu {
    display: block;
}
.dropdown-hover > .dropdown-menu:hover {
    display: block;
}
.dropdown-hover.dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
}
