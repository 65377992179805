[class*='__menu'] {
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);

    [class*='__option']:hover {
        background-color: var(--#{$prefix}tertiary-bg);
        color: var(--#{$prefix}white);
    }
}
[class*='__menu'] {
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);

    [class*='__option']:hover {
        background-color: var(--#{$prefix}tertiary-bg);
        color: var(--#{$prefix}white);
    }
    [class*='__option']:not([class*='__option--is-selected']):hover {
        background-color: var(--#{$prefix}tertiary-bg);
        color: var(--#{$prefix}white);
    }
    [class*='__option--is-selected'] {
        background-color: var(--#{$prefix}gray-200);
        color: var(--#{$prefix}white);
    }
    [class*='__option--is-focused']:not([class*='__option--is-selected']) {
        background-color: var(--#{$prefix}tertiary-bg);
        color: var(--#{$prefix}white);
    }

    [class*='__option--is-disabled'] {
        background-color: var(--#{$prefix}secondary-bg);
        color: var(--#{$prefix}body-color);
    }
}

[class*='__control'] {
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    border-color: var(--bs-border-color);

    [class*='__single-value'] {
        color: inherit;
    }
    [class*='__multi-value'] {
        background-color: var(--bs-gray-400);
        color: var(--bs-white);

        [class*='__multi-value__label'] {
            color: var(--bs-white);
        }
    }
}
