*::-webkit-scrollbar,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-button,
div::-webkit-scrollbar {
    width: 15px !important;
    height: 5px !important;
    background-color: var(--bs-tertiary-bg) !important;
    border: none !important;
}
*::-webkit-scrollbar-thumb {
    background: var(--bs-secondary-bg) !important;
    border: none !important;
}
*::-webkit-scrollbar-button {
    display: none !important;
}
