// Overflow Hidden
.o-hidden {
    overflow: hidden !important;
}

.fluid {
    width: 100%;
    height: 100%;
}

.w-100px {
    width: 100px;
}
.w-150px {
    width: 150px;
}
.w-200px {
    width: 200px;
}
.w-250px {
    width: 250px;
}
.w-300px {
    width: 300px;
}

.w-350px {
    width: 350px;
}

.center-parent {
    position: relative;
}

.fa-xxs {
    font-size: 0.5em;
}

.center-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 0.2;
}
.opacity-2 {
    opacity: 0.4;
}
.opacity-3 {
    opacity: 0.6;
}
.opacity-4 {
    opacity: 0.8;
}
.opacity-5 {
    opacity: 1;
}

@for $i from 1 through 20 {
    .start-#{$i * 5} {
        left: $i * 5%;
    }
}

@for $i from 1 through 20 {
    .top-#{$i * 5} {
        top: $i * 5%;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
