#root {
    height: 100vh;
}

#wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .wrapper-content {
        flex: 1 0 auto;
    }
}
