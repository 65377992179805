// Topbar

.topbar-offset {
    margin-top: $topbar-base-height;
}

.topbar {
    height: $topbar-base-height;
    background-color: var(--lf-project-color-1, #364453) !important;

    .dropdown-menu {
        &[data-bs-popper] {
            left: unset;
        }
    }

    .brand {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 0;

        img {
            width: 33px;
            height: 33px;
        }

        .text-logo {
            padding: 5px;
            font-size: 32px;
            line-height: 1;
            text-align: center;
            padding-bottom: 15px;
            font-weight: 800;
        }
    }

    .topbar-divider {
        width: 0;
        border-right: 1px solid $border-color;
        height: calc(#{$topbar-base-height} - 2rem);
        margin: auto 1rem;
    }

    .nav-item {
        .nav-link {
            height: $topbar-base-height;
            display: flex;
            align-items: center;
            padding: 0 0.75rem;
            &:focus {
                outline: none;
            }
        }
        &:focus {
            outline: none;
        }
    }
    .dropdown {
        position: static;
        .dropdown-menu {
            width: 70%;
            right: calc($grid-gutter-width / 2);
        }
    }
    .dropdown-list {
        padding: 0;
        border: none;
        overflow: hidden;
        .dropdown-header {
            background-color: var(--lf-project-color-2, $primary);
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            color: $white;
        }
        .dropdown-item {
            white-space: normal;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-left: 1px solid var(--#{$prefix}border-color);
            border-right: 1px solid var(--#{$prefix}border-color);
            border-bottom: 1px solid var(--#{$prefix}border-color);
            line-height: 1.3rem;
            .dropdown-list-image {
                position: relative;
                height: 2.5rem;
                width: 2.5rem;
                img {
                    height: 2.5rem;
                    width: 2.5rem;
                }
                .status-indicator {
                    background-color: var(--#{$prefix}gray-200);
                    height: 0.75rem;
                    width: 0.75rem;
                    border-radius: 100%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    border: 0.125rem solid $white;
                }
            }
            .text-truncate {
                max-width: 10rem;
            }
            &:hover {
                background-color: var(--#{$prefix}gray-100);
            }
            &:active {
                background-color: var(--#{$prefix}gray-200);
                color: $gray-900;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .dropdown {
            position: relative;
            .dropdown-menu {
                width: auto;
                right: 0;
            }
        }
        .dropdown-list {
            width: 30rem !important;
            .dropdown-item {
                .text-truncate {
                    max-width: 13.375rem;
                }
            }
        }
    }
}

.topbar.navbar-dark {
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: fade-out($white, 0.2);
                &:hover {
                    color: $white;
                }
                &:active {
                    color: $white;
                }
            }
        }
    }
}
