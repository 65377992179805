footer.sticky-footer {
    padding: 1.5rem 0;
    flex-shrink: 0;
    .copyright {
        line-height: 1;
        font-size: 0.8rem;
    }
}

body.sidebar-toggled {
    footer.sticky-footer {
        width: 100%;
    }
}
